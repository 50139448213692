//
// Override global variables
//

// Theme colors
// Primary colors
$primary:       									#63BF71;
$primary-hover:    									#63BF71;
$primary-light:    									#d6fcdc; // our own light color is missing
$primary-inverse:  									#FFFFFF;

// Secondary colors
$secondary:     									#A1A5A6;
$secondary-hover:  									#F2F2F2;
$secondary-light:  									#F2F2F2;
$secondary-inverse:									#F2F2F2;

// Success colors
$success:       									#63BF71;
$success-hover:    									#63BF71;
$success-light:    									#d6fcdc;
$success-inverse:  									#FFFFFF;

// Info colors
$info:       									    #6993FF;
$info-light:    									#E1E9FF;
$info-hover:    									#4A7DFF;
$info-inverse:  									#FFFFFF;
